<template>
  <div class="pay_container">
    <el-row class="pay_header">
      <el-col :span="24">{{ name }}</el-col>
    </el-row>
    <div class="pay_main">
      <p class="count">倒计时：{{ count }}</p>
      <p>就诊人：{{ patName }} ，卡号：{{ query.patCardNo }} {{ query.admissionNum }}</p>
      <p>
        <span class="red">请在120秒内进行操作！</span>
      </p>
      <img :src="'data:image/png;base64,' + query.jump" alt="">
      <p style="font-size:22px">
        <span class="red">使用微信“扫一扫”进行操作,请确保在微信小程序支付完成后点击“支付完成”按钮</span>
      </p>
      <!-- <p style="font-size:22px">
        <span class="red">扫码后请耐心等待系统打印缴费凭证，请勿点击屏幕取消按钮！</span>
      </p> -->
      <el-button type="primary" :disabled="disabledBtn" @click.native="medicareRecord">{{ buttonText }}</el-button>
      <el-button type="danger" @click.native="cancel">取消</el-button>
    </div>
    <Foo></Foo>
  </div>
</template>
<script>
import Foo from '../footer/index'
import { jsonPost, formPost } from "@/baseAPI";
export default {
  components: {
    Foo
  },
  data () {
    return {
      name: '',
      patName: '',
      deptName: '',
      count: '',
      timer: '',
      timer1: '',
      query: {},
      imgurl: "",
      remark: '',
      buttonText: '支付完成',
      disabled: false,
      timer2: null,
      countDown: 60,
      disabledBtn: false
    }
  },
  created () {
    this.name = this.$route.query.name
    this.patName = this.$route.query.patName
    this.deptName = this.$route.query.deptName
    this.query = this.$route.query
    this.countdown()
    this.startTimer()
  },
  beforeDestroy () {
    clearInterval(this.timer);
    this.timer = null;
  },
  methods: {
    startTimer () {
      this.disabledBtn = true
      this.buttonText = `支付完成(${this.countDown})`
      this.timer2 = setInterval(() => {
        this.countDown--
        this.buttonText = `支付完成(${this.countDown})`
        if (this.countDown === 0) {
          clearInterval(this.timer2)
          this.disabledBtn = false
          this.buttonText = '支付完成'
          this.countDown = 60
        }
      }, 1000)
    },
    async medicareRecord () {
      const { data: res } = await jsonPost(
        "/outpatient/medicareRecord",
        {
          patCardNo: this.query.patCardNo,
          orderNum: this.query.orderNum,
        }
      );
      if (res.code == 0) {
        let data = res.data
        data.sort((a, b) => b.id - a.id);
        // 支付未完成继续轮训
        if ((typeof data[0] !== 'undefined' && data[0].isPay == 0) || res.data.length == 0) {
          this.timer = setTimeout(() => {
            this.medicareRecord()
          }, 2000)
        } else if (data[0].isPay == 1) {
          this.$router.push({
            path: '/succeedOut',
            // query: data[0].data,
            query: {
              orderNum: data[0].hospitalOrderNum,
              patName: this.query.patName,
              doctorName: this.query.doctorName,
              deptName: this.query.deptName,
              patCardNo: data[0].medicalCardNo,
              cost: data[0].cost,
              outTradeNo: data[0].outTradeNo,
              remark: data[0].remark,
              receiptNum: data[0].receiptNum,
              date: data[0].createTime,
              name: this.query.name,
              budgeting: data[0].budgeting,
              medicalInsurance: data[0].medicalInsurance,
              // admissionNum: this.query.admissionNum
            }
          })
        }
      }
    },
    async getInvoice (receiptNum) {
      const loading = this.$loading({
        lock: true,
        text: '加载中...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      const { data: res } = await jsonPost(
        "/outpatient/viewInvoice",
        {
          agtOrdNum: this.query.outTradeNo,
          patCardNo: this.query.patCardNo,
          invoiceNum: receiptNum,
          // agtOrdNum: "2301061605229155",
          // patCardNo: "100765865",
          // invoiceNum: "WX202301060351",
        }
      );
      if (res.code === 0) {
        loading.close()
        this.invoiceUrl = res.data.invoiceUrl
        this.print(res.data.invoiceUrl)
      } else {
        loading.close()
      }
    },
    print (invoiceUrl) {
      let _this = this
      const params = {
        orderNum: this.query.orderNum,
        name: encodeURI(this.query.patName),
        patCardNo: this.query.patCardNo,
        cost: this.query.cost,
        outTradeNo: this.query.outTradeNo,
        guideListInfo: encodeURI(this.remark),
        invoiceUrl,
        deptName: encodeURI(this.query.deptName),
        doctorName: encodeURI(this.query.doctorName),
        // date: this.query.date,
      }
      Promise.all([_this.$api.printOut(params)]).then((data) => {

      });
    },
    countdown () {
      const TIME_COUNT = 120
      this.count = TIME_COUNT
      this.timer1 = setInterval(() => {
        if (this.count > 0 && this.count <= TIME_COUNT) {
          this.count--
          if (this.count == 0) {
            this.$router.push('/home')
          }
        } else {
          clearInterval(this.timer1)
          this.timer1 = null
        }
      }, 1000)
    },
    cancel () {
      if (this.name == "预约挂号" || this.name == "当天挂号") {
        this.cancelReg()
      }
      this.$router.push('/home')
    },
    // 取消挂号
    cancelReg () {
      let _this = this
      let params = {
        appointmentId: this.query.appointmentId
      }
      Promise.all([_this.$api.unlockReg(params)]).then((data) => {
        this.$message({
          message: '您已取消挂号',
          type: 'warning'
        })
      });

    },
  },
  destroyed () {
    clearInterval(this.timer)
    clearInterval(this.timer1)
  }
}
</script>
<style >
.pay_header {
  background-color: #81ace4;
  height: 100px;
  font-size: 38px;
  font-weight: 700;
  line-height: 100px;
  color: white;
  letter-spacing: 20px;
  padding-left: 30px;
}

.pay_main {
  margin-top: 40%;
  text-align: center;
}

.pay_main .count {
  position: absolute;
  top: 60px;
  right: 40px;
  font-size: 34px;
}

.pay_main p {
  color: #2259a1;
  margin-top: 30px;
  font-size: 50px;
}

.pay_main .red {
  color: red;
  font-size: 48px;
}

.pay_main img {
  width: 400px;
  height: 400px;
}

.pay_main .el-button {
  margin: 40px;
  font-size: 40px;
  width: 260px;
  height: 130px;
}

.pay_footer {
  position: fixed !important;
  bottom: 0;
  left: 0;
  z-index: 1;
  height: 30px;
  width: 100%;
  background-color: #81ace4;
  font-size: 14px;
  color: white;
  text-align: center;
  line-height: 30px;
}
</style>
